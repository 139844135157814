import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

class Flickr extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteTagline = data.site.siteMetadata.description
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        tagline={siteTagline}
      >
        <SEO title="Instagram" />
        <h1>Instagram</h1>
        <p>
          I am currently not able to show my Instagram posts on this page.
          <br />
          If you are interested, please look at my{" "}
          <a
            href="https://www.instagram.com/serith/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram profile
          </a>
          .
        </p>
      </Layout>
    )
  }
}

export default Flickr

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
